<template>
  <div class="text-lightgrey-200">
    <div class="mx-auto max-w-8xl py-12 px-6 sm:py-32 lg:grid lg:grid-cols-3 lg:gap-x-12 lg:px-8 lg:py-40">
      <div>
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-white">
          Everything you need
        </h2>
        <p class="mt-2 text-4xl font-bold tracking-tight text-lightgrey-100">
          The Go-To Gallery for Artists<span class="text-orange-500">.</span>
        </p>
        <p class="mt-6 text-base leading-7 text-lightgrey-200">
          MattePaint has everything you need. From managing a production team to finding the perfect asset.
        </p>
      </div>
      <div class="mt-20 lg:col-span-2 lg:mt-0">
        <dl class="grid grid-cols-1 gap-12 sm:grid-flow-col sm:grid-cols-2 sm:grid-rows-4">
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <CheckIcon class="absolute mt-1 h-6 w-6 text-green-600" aria-hidden="true" />
              <p class="ml-10 text-lg font-semibold leading-8 text-orange-400">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-10 text-base leading-7 text-lightgrey-200">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { CheckIcon } from '@heroicons/vue/24/outline'

const features = [
  {
    name: 'Over 100,000 Assets',
    description: 'Find assets from a huge selection, shot all over the world, at multiple times of the day.',
  },
  {
    name: '32bit Exr Downloads',
    description: 'Drag and drop downloads for 3D artists and Compositors, with full colour range and the absolute highest image quality possible.' 
  },
  {
    name: 'Free JPG Downloads',
    description: 'Ensure your concept artists create their work using assets which can be used later in post-production.',
  },
  {
    name: 'Over 10,000 HDRIs',
    description: 'The worlds largest library of HDRI Sky imagery, with over 10,000 assets shot at 90 second intervals.' 
  },
  {
    name: 'Up to 50,000px Wide',
    description: 'Massive resolution, 360° images, suitable for entire sequences on 4k productions.' 
  },
  {
    name: '16bit Multi-Resolution RAW Downloads',
    description: 'High quality downloads that allow artists to colour correct images up to 10X faster than a JPG.' 
  },
  {
    name: 'Masked PNGs For Quick Concepting',
    description: 'Skip the masking. Every single MattePaint asset has a masked download option. Perfect for the early phases of production.' 
  },
  {
    name: 'Team Management Platform',
    description: 'Monitor and control your credit spend intelligently, with our full-featured project and team management platform.' 
  },
]
</script>
